var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.onModalClose
    }
  }, [_c('ResourceModal', {
    attrs: {
      "headline": _vm.modalHeadline,
      "type": _vm.modalType,
      "isVocationalSchool": _vm.isVocationalSchool
    },
    on: {
      "close": _vm.onModalClose
    }
  })], 1) : _vm._e(), _c('v-form', [_c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToCourseDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" " + _vm._s(_vm.$route.params.id) + " - " + _vm._s(_vm.course.courseName) + " ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk blant ressurser",
            "single-line": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "addResource"
          },
          on: {
            "click": _vm.newResource
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Legg til ressurs ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.resourceList,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "seeResource"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewResource(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis ressurs")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "data-cy": "deleteResource"
                },
                on: {
                  "click": function click($event) {
                    return _vm.deleteResource(item.userId);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett ressurs")])])];
      }
    }, {
      key: "item.userType",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [_vm._v(" " + _vm._s(_vm.getUserRole(value) === "Customer" ? "Ekstern ressurs" : "AOF-ansatt") + " ")];
      }
    }, {
      key: "item.email",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.mobile",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.roleName",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.noTeachingHours",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.registeredWorkingHours",
      fn: function fn(_ref9) {
        var value = _ref9.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref10) {
        var value = _ref10.value;
        return [_vm._v(" " + _vm._s(value) + " ")];
      }
    }], null, true)
  })]], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }