
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { defineComponent, onMounted, ref, computed } from "@vue/composition-api";
import { openNotification, runStoreAction } from "@/shared/helpers/store.helpers";
import BaseModal from "@/components/shared/BaseModal.vue";
import ResourceModal from "@/components/course/resources/ResourceModal.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { StoreModules } from "@/store/store-modules.enum";
import { StoreActions } from "@/store/store.actions";
import { api } from "@/api/api";
import { ApiGetCourseResourceDto } from "@/api/generated/Api";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { getStatusText, getUserRole } from "@/shared/helpers/resourceHelpers";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { CourseParticipantType } from "@/shared/enums/courseParticipantType.enum";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";

interface resourceItem {
  appliedViaForm: boolean;
  courseId: number;
  inserted: string;
  insertedBy: number;
  requiresElectronicInvoice: boolean;
  roleName: string;
  status: string;
  updated: string;
  updatedBy: number;
  userFullName: string;
  userId: number;
  userType: string;
}

export default defineComponent({
  name: "CourseResoursesPage",
  components: {
    ResourceModal,
    BaseModal,
    BaseTableFiltered,
    BaseLayout,
  },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();
    const course = computed(() => store.state.courses.course);
    const showModal = ref(false);
    const search = ref("");
    const modalHeadline = "Legg til ressurs";

    const headers = [
      { text: "Navn", value: "name" },
      { text: "Handlinger", value: "actions", sortable: false },
      {
        text: "Ressurstype",
        value: "userType",
        filter: true,
      },
      {
        text: "E-post",
        value: "email",
        filter: true,
      },
      {
        text: "Mobil",
        value: "mobile",
        filter: true,
      },
      {
        text: "Rolle",
        value: "roleName",
        filter: true,
      },
      {
        text: "Undervisningstimer iht kontrakt",
        value: "noTeachingHours",
        filter: true,
      },
      {
        text: "Registrerte undervisningstimer",
        value: "registeredWorkingHours",
        filter: true,
      },
    ];

    const resourceList = ref<ApiGetCourseResourceDto[]>([]);

    onMounted(async () => {
      await loadResources();
    });

    const viewResource = (item: resourceItem) => {
      const userRole: string = getUserRole(item.userType);
      if (userRole === CourseParticipantType.Customer) {
        runStoreAction(store, StoreModules.Contacts, StoreActions.ContactsActions.FetchPerson, item.userId);
        router.push({
          path: `/kurset/${route.params.id}/resources/${item.userId}`,
        });
      } else {
        router.push({
          path: `/kurset/${route.params.id}/resources/${item.userId}`,
          query: { type: "employee" },
        });
      }
    };

    const deleteResource = async (resourceId: number) => {
      await api.course.deleteCourseResourcesByCourseIdAsync(+route.params.id, resourceId);
      openNotification(store, NotificationItemType.Success, `Ressursen er slettet`);
      await loadResources();
    };

    const newResource = () => {
      showModal.value = true;
    };

    const onModalClose = () => {
      showModal.value = false;
      loadResources();
    };

    const loadResources = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = await api.course.getCourseResourcesByCourseIdAsync(+route.params.id);
        resourceList.value = response.data;
      });
    };

    return {
      course,
      viewResource,
      formatRelative,
      newResource,
      onModalClose,
      getStatusText,
      getUserRole,
      deleteResource,
      resourceList,
      headers,
      showModal,
      modalHeadline,
      modalType: ModalType.Add,
      search,
      isVocationalSchool: computed(() => isVocationalSchool(store.state.plans.studyplan.mainCourseId)),
      navigateToCourseDashboard: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
    };
  },
});
