var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.type
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      },
      "submit": _vm.onSubmit
    }
  }, [_c('v-switch', {
    attrs: {
      "data-cy": "aofEmployee",
      "label": "AOF ANSATTE"
    },
    model: {
      value: _vm.aofEmployee,
      callback: function callback($$v) {
        _vm.aofEmployee = $$v;
      },
      expression: "aofEmployee"
    }
  }), _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('section', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.aofEmployee ? _c('PersonSearchInput', {
    attrs: {
      "label": "Legg til (adressebok)",
      "cypressId": "selectFromAddressbook"
    },
    model: {
      value: _vm.resourceData.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.resourceData, "userId", $$v);
      },
      expression: "resourceData.userId"
    }
  }) : _vm._e(), _vm.aofEmployee ? _c('EmployeeSearchInput', {
    attrs: {
      "label": "Legg til (AOF ansatte)",
      "cypressId": "selectFromEcit"
    },
    model: {
      value: _vm.resourceData.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.resourceData, "userId", $$v);
      },
      expression: "resourceData.userId"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "data-cy": "roleNames",
      "label": "Rolle",
      "items": _vm.resourceRoleList,
      "item-text": "name",
      "item-value": "name",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.resourceData.roleName,
      callback: function callback($$v) {
        _vm.$set(_vm.resourceData, "roleName", $$v);
      },
      expression: "resourceData.roleName"
    }
  })], 1), _vm.aofEmployee ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "Kosttype",
      "items": _vm.costTypeList,
      "item-text": function itemText(item) {
        return item.name;
      },
      "item-value": function itemValue(item) {
        return item.id;
      },
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.costTypeId,
      callback: function callback($$v) {
        _vm.costTypeId = $$v;
      },
      expression: "costTypeId"
    }
  })], 1) : _vm._e(), _vm.aofEmployee ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "teachingHours",
      "label": "Undervisningstimer",
      "type": "number",
      "max": "1000",
      "min": "1",
      "rules": _vm.validateNotEmpty
    },
    model: {
      value: _vm.hours,
      callback: function callback($$v) {
        _vm.hours = $$v;
      },
      expression: "hours"
    }
  })], 1) : _vm._e()], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }